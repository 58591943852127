<template>
  <!-- Sidebar -->
  <reviewer-side-bar> </reviewer-side-bar>

  <!-- Sidebar -->
  <section class="home-section">
    <!-- Header -->
    <reviewer-nav-bar>
      <ol class="list-reset flex">
        <li>
          <router-link to="/admin/review"
            ><span class="text-grey-300 ">Home</span></router-link
          >
        </li>
        <li><span class="text-gray-500 mx-2">/</span></li>
        <li>
          <a href="#" class="pointer-events-none  text-primary-600"
            >Declined Licenses Report</a
          >
        </li>
      </ol>
    </reviewer-nav-bar>
    <!-- Header -->

    <!-- Main Content -->
    <div class="home-content">
      <div class="container mx-auto px-4 sm:px-8">
        <div class="py-8">
          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
            <div class="grid grid-rows-1">
              <div class="grid grid-cols-3">
                <div class="col-span-1 mt-5">
                  <div
                    class="input-group relative flex flex-wrap items-stretch w-full mb-4 mt-1"
                  >
                    <div class="mb-3 xl:w-full">
                      <label for="" class="ml-4">License Type</label>
                      <select
                        class="form-select appearance-none block w-full px-6 ml-4 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        v-model="licenseType"
                        @change="filterLicenses()"
                      >
                        <option selected value="newLicense"
                          >New License Applications</option
                        >
                        <option value="renewal">Renewed Applications</option>

                        <option value="goodstanding"
                          >Goodstanding Applications</option
                        >
                      </select>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="
                        inline-block
                        px-6
                        py-2
                        ml-4
                        mb-4
                        -mt-4
                        border-2 border-primary-600
                        text-primary-600
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        rounded
                        hover:bg-primary-600 hover:bg-opacity-5 hover:text-white
                        focus:outline-none focus:ring-0
                        transition
                        duration-150 
                        ease-in-out
                      "
                    @click="clearFilters()"
                  >
                    <i class="fa fa-close"></i>
                    Clear Filters
                  </button>
                </div>
              </div>
            </div>

            <div
              class="inline-block min-w-full   rounded-lg overflow-hidden bg-primary-800"
            >
              <vue-table-lite
                :is-loading="licenseTable.isLoading"
                :columns="licenseTable.columns"
                :rows="licenseTable.rows"
                :total="licenseTable.totalRecordCount"
                :sortable="licenseTable.sortable"
                @is-finished="tableLoadingFinish"
                @row-clicked="rowClicked"
                @do-search="doSearch"
              ></vue-table-lite>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main Content -->
  </section>
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import ReviewerNavBar from "./SharedComponents/navBar.vue";
import ReviewerSideBar from "./SharedComponents/sideNav.vue";
import VueTableLite from "vue3-table-lite";

export default {
  components: {
    ReviewerNavBar,
    ReviewerSideBar,
    VueTableLite,
  },
  computed: {},

  setup() {
    const store = useStore();
    let allData = ref([]);
    let licenseType = ref("newLicense");

    let licenseTable = ref({ isLoading: true });
    let modalData = ref({ change: 0 });
    let tableData = reactive([]);
    const searchTerm = ref("");

    const filterLicenses = () => {
      licenseTable.value.isLoading = true;
      getLicenses([
        { key: "licenseType", value: licenseType.value },
        { key: "page", value: 0 },
        { key: "size", value: 10 },
        searchTerm.value,
      ]);
    };
    const clearFilters = () => {
      licenseType.value = "newLicense";
      licenseTable.value.isLoading = true;
      getLicenses([
        { key: "licenseType", value: licenseType.value },
        { key: "page", value: 0 },
        { key: "size", value: 10 },
      ]);
    };
    const getLicenses = (apiParameters) => {
      store
        .dispatch("report/getDeclinedLicensesReport", [
          { params: apiParameters },
        ])
        .then((res) => { 
          tableData = [];
          let licenseData = res.data?.data;
          licenseData?.rows?.forEach((element) => {
            tableData.push({
              LicenseNumber: element.newLicenseCode
                ? element.newLicenseCode
                : element.renewalCode
                ? element.renewalCode
                : element.goodStandingCode
                ? element.goodStandingCode
                : "",
              FirstName: element.name ? element.name : "",
              FatherName: element.fatherName ? element.fatherName : "",
              GrandfatherName: element.grandFatherName
                ? element.grandFatherName
                : "",
              ReviewerName: element.reviewerName ? element.reviewerName : "",
              Remark: element.remark ? element.remark : "",
            });
          });
          allData.value = tableData;
          licenseTable.value = {
            isLoading: false,
            columns: [
              {
                label: "LicenseNumber",
                field: "LicenseNumber",
                width: "10%",
                sortable: true,
                isKey: true,
              },
              {
                label: "Name",
                field: "FirstName",
                width: "15%",
                sortable: true,
              },
              {
                label: "Father Name",
                field: "FatherName",
                width: "15%",
                sortable: true,
              },
              {
                label: "Grandfather Name",
                field: "GrandfatherName",
                width: "10%",
                sortable: true,
              },
              {
                label: "ReviewerName",
                field: "ReviewerName",
                width: "10%",
                sortable: true,
              },
              {
                label: "Remark",
                field: "Remark",
                width: "45%",
                sortable: true,
              },
            ],
            rows: tableData,
            totalRecordCount: licenseData?.count,
            sortable: {
              order: "id",
              sort: "asc",
            },
          };
        });
    };

    const filterExpertLevel = () => {
      licenseTable.value.isLoading = true;
      getLicenses([
        { key: "licenseType", value: licenseType.value },
        { key: "page", value: 0 },
        { key: "size", value: 10 },
      ]);
    };
    const tableLoadingFinish = () => {
      let elements = document.getElementsByClassName("edit-btn");

      Array.prototype.forEach.call(elements, function(element) {
        if (element.classList.contains("edit-btn")) {
          element.addEventListener("click", rowClicked());
        }
      });
    };

    const rowClicked = (row) => {
      if (row != undefined) {
        row = JSON.parse(JSON.stringify(row));
        modalData.value.change++;
        modalData.value.data = row ? row.data : {};
      }
    };
    const doSearch = (offset, limit, order, sort) => {
      licenseTable.value.isLoading = true;

      setTimeout(() => {
        licenseTable.value.isReSearch = offset == undefined ? true : false;
        offset = offset / 10;
        if (sort == "asc") {
          getLicenses([
            { key: "licenseType", value: licenseType.value },
            { key: "page", value: offset },
            { key: "size", value: limit },
          ]);
        } else {
          getLicenses([
            { key: "licenseType", value: licenseType.value },
            { key: "page", value: offset },
            { key: "size", value: limit },
          ]);
        }
        licenseTable.value.sortable.order = order;
        licenseTable.value.sortable.sort = sort;
      }, 600);
    };
    onMounted(() => {
      getLicenses([
        { key: "licenseType", value: licenseType.value },
        { key: "page", value: 0 },
        { key: "size", value: 10 },
      ]);
    });

    return {
      doSearch,
      clearFilters,
      licenseTable,
      licenseType,
      filterLicenses,
      rowClicked,
      searchTerm,
      modalData,
      tableLoadingFinish,
      filterExpertLevel,
    };
  },
};
</script>
